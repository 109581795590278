import isArraysEqual from './isArraysEqual'

export default function convertToAnswer(
    surveyId,
    question,
    { selected, text },
) {
    if (question.type === 'single') {
        const selectedVariant = question.answers.find(answer => {
            return selected.includes(answer.title)
        })

        const answerIndex = question.answers.indexOf(selectedVariant)

        const answer = {
            surveyId,
            questionId: question.id,
            question: question.title,
            answerId: answerIndex + 1 + '',
            answer: selected[0],
            isValid: selectedVariant.isValid,
        }

        return answer
    } else if (question.type === 'multiple') {
        const validAnswers = question.answers
            .filter(a => a.isValid)
            .map(a => a.title)
        const userAnswers = selected

        const isValid = isArraysEqual(validAnswers, userAnswers)

        const answer = {
            surveyId,
            questionId: question.id,
            question: question.title,
            answer: selected.join(';'),
            isValid: isValid,
        }

        return answer
    } else {
        const answer = {
            surveyId,
            questionId: question.id,
            question: question.title,
            answer: text,
        }

        return answer
    }

    return {}
}
