export const MechanicIcon1 = () => {
    return (
        <svg
            width="71"
            height="70"
            viewBox="0 0 71 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_302)">
                <path
                    d="M25.2016 0H0V25.2016C0 28.7155 2.84611 31.5616 6.36002 31.5616H31.5616V6.36002C31.5616 2.84611 28.7155 0 25.2016 0Z"
                    fill="#5FAF2D"
                />
                <path
                    d="M38.7563 25.2016V0H63.9579C67.4718 0 70.318 2.84611 70.318 6.36002V31.5616H45.1164C41.6025 31.5616 38.7563 28.7155 38.7563 25.2016Z"
                    fill="#5FAF2D"
                />
                <path
                    d="M61.1038 44.9018C62.6223 44.9018 63.8545 46.134 63.8545 47.6525V63.5287H47.9783C46.4598 63.5287 45.2276 62.2964 45.2276 60.778V44.9018H61.1038ZM61.1038 38.5417H45.2196H38.8596V44.9018V60.778C38.8596 65.8024 42.9459 69.8887 47.9704 69.8887H63.8466H70.2066V63.5287V47.6525C70.2145 42.6281 66.1282 38.5417 61.1038 38.5417Z"
                    fill="#5FAF2D"
                />
                <path
                    d="M31.5616 44.7904V69.992H6.36002C2.84611 69.992 0 67.1459 0 63.632V38.4304H25.2016C28.7155 38.4304 31.5616 41.2845 31.5616 44.7904Z"
                    fill="#5FAF2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_302">
                    <rect width="70.318" height="70" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
