import { useModal } from 'src/lib/providers/modal'
import styles from './style.module.css'
import { PassedIcon } from 'src/assets/images/passed-icon'
import Button from 'src/components/button'
import { useState } from 'react'

import { COURSE } from 'src/data'
import { useAuth } from 'src/lib/providers/auth'

import convertToAnswer from 'src/lib/utils/convertToAnswer'

const QuizPopup = () => {
    const { closeModal, context } = useModal()
    const questions = COURSE.intro.questions
    const { sendAnswer } = useAuth()

    const [currentQuestion, setCurrentQuestion] = useState(questions[0])
    const [selected, setSelected] = useState([])

    const handleChange = answer => {
        setSelected([answer])
    }

    const handleNext = () => {
        const currentIndex = questions.findIndex(
            question => question.id === currentQuestion.id,
        )

        sendAnswer(
            convertToAnswer('intro', currentQuestion, {
                selected: [selected[0].title],
                text: '',
            }),
        )

        if (currentIndex !== -1 && currentIndex < questions.length - 1) {
            const nextQuestionIndex = selected[0].nextQuestion
                ? selected[0].nextQuestion
                : currentIndex + 1
            if (questions[nextQuestionIndex]) {
                setCurrentQuestion(questions[nextQuestionIndex])
            } else {
                closeModal()
            }
        } else {
            // no more questions
            closeModal()
        }

        setSelected([])
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.description}>
                    Пожалуйста, ответьте на пару вопросов, чтобы начать обучение
                </div>

                <div className={styles.question}>
                    <h2>{currentQuestion.title}</h2>
                    <div className={styles.variants}>
                        {currentQuestion.answers.map((answer, index) => {
                            return (
                                <div key={index}>
                                    <label
                                        className={`${styles.answerLabel} ${
                                            selected.some(
                                                selectedAnswer =>
                                                    answer.title ===
                                                    selectedAnswer.title,
                                            )
                                                ? styles.answerLabelSelected
                                                : ''
                                        }`}>
                                        {answer.title}
                                        <input
                                            type="radio"
                                            value={answer.title}
                                            checked={selected.some(
                                                selectedAnswer =>
                                                    answer.title ===
                                                    selectedAnswer.title,
                                            )}
                                            onChange={() => {
                                                handleChange(answer)
                                            }}
                                        />
                                        <span
                                            className={
                                                styles.answerCheckmark
                                            }></span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>

                    <Button
                        fullWidth
                        disabled={!selected.length}
                        onClick={handleNext}>
                        Продолжить
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default QuizPopup
