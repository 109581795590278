import styles from './style.module.css'
import Button from 'src/components/button'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/layout'

export default function Feedback() {
    const { openModal } = useModal()
    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <svg
                        width="235"
                        height="231"
                        viewBox="0 0 235 231"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M162.945 9.79004C146.335 29.42 127.838 40.745 101.035 54.7125L38.748 87.1775L65.5505 215.528C67.438 224.21 74.988 230.628 84.048 230.628H118.023L91.598 147.578H122.931C149.733 147.578 172.383 151.353 194.278 158.148L208.623 162.678L170.873 0.352539L162.945 9.79004ZM12.7005 100.768C4.3955 103.41 -0.512002 109.828 1.3755 118.133L6.6605 140.783C8.548 149.088 13.833 151.353 22.893 151.353H31.198L19.873 98.5025L12.7005 100.768ZM234.671 81.515L231.273 64.905C229.763 56.9775 223.346 53.2025 215.04 53.2025H204.848L216.173 102.278L225.233 98.125C231.65 95.105 236.18 89.065 234.671 81.515Z"
                            fill="#5FAF2D"
                        />
                    </svg>
                    <div className={styles.content}>
                        <h2>Поделитесь обратной связью!</h2>
                        <div className={styles.description}>
                            Благодарим за прохождение базового курса по
                            аналитике данных. Мы не хотим останавливаться и
                            планируем добавлять новые уровни! Нам очень важно
                            услышать ваше мнение: какие темы и материалы вас
                            интересуют?
                        </div>
                        <Button onClick={() => openModal(MODAL.FEEDBACK)}>
                            Пройти опрос
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
