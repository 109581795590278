import styles from './style.module.css'
import Button from 'src/components/button'

import heroMobileImage from 'src/assets/images/KV_main-cropped.svg'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/layout'

export default function Hero() {
    const { openModal } = useModal()

    return (
        <div className={styles.hero}>
            <div className={styles.heroContainer}>
                <h1>Академия Dialog&nbsp;X5</h1>
                <div className={styles.heroBadge}>Базовый уровень</div>
                {window.innerWidth < 768 && (
                    <div className={styles.mobileImageContainer}>
                        <img src={heroMobileImage} />
                    </div>
                )}
                <div className={styles.description}>
                    <div className={styles.subHeading}>
                        Погрузитесь в увлекательный мир аналитики с
                        Dialog&nbsp;X5!
                    </div>
                    <p>
                        Аналитические сервисы Dialog X5 помогают принимать
                        обоснованные решения и развивать бизнес, опираясь на
                        большие данные экосистемы X5 Group. Вы получаете
                        актуальную информацию о трендах категорий, изменении
                        покупательского поведения и других метриках, важных для
                        вашего бизнеса. Сервисы Dialog X5 созданы специально для
                        поставщиков X5 Group.
                    </p>
                </div>

                <Button onClick={() => openModal(MODAL.REGISTER)}>
                    Зарегистрироваться
                </Button>
            </div>
        </div>
    )
}
