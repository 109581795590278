export const CheckedIcon = () => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_308_83)">
                <path
                    d="M21.6206 25H0.724148C0.323453 25 0 24.6765 0 24.2759V3.37936C0 1.51347 1.51347 0 3.37936 0H11.1953C11.596 0 11.9195 0.323453 11.9195 0.724148V2.65521C11.9195 3.0559 11.596 3.37936 11.1953 3.37936H4.34489C3.81143 3.37936 3.37936 3.81143 3.37936 4.34489V21.6206H20.6551C21.1886 21.6206 21.6206 21.1886 21.6206 20.6551V16.5878C21.6206 16.1871 21.9441 15.8637 22.3448 15.8637H24.2759C24.6765 15.8637 25 16.1871 25 16.5878V21.6182C25 23.4865 23.4865 25 21.6206 25Z"
                    fill="white"
                />
                <path
                    d="M22.3665 0.43457C21.3189 0.43457 20.4209 1.02113 19.9961 1.97218L14.7484 13.7203L11.4511 8.2264H6.8335L11.9412 16.5638C12.3732 17.2855 13.037 17.7465 13.9615 17.7465H15.3181C16.3271 17.7465 17.1357 17.2276 17.5678 16.2741L24.7224 0.43457H22.3665Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_308_83">
                    <rect width="25" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
