export const X5dialogIcon = () => {
    return (
        <svg
            width="103"
            height="30"
            viewBox="0 0 103 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.8736 25.2303L21.0592 20.5526L18.303 25.2303H15.0034L19.2434 18.1836L14.9229 11.6426H18.37L21.1379 16.0098L23.7775 11.6426H27.0771L22.8264 18.3971L27.1936 25.2293H23.8736V25.2303Z"
                fill="white"
            />
            <path
                d="M37.9189 20.6108C37.9189 23.5999 35.6674 25.4632 32.7172 25.4632C30.1162 25.4632 27.9259 24.0269 27.7124 20.902H30.5267C30.7209 22.2606 31.8078 22.8429 32.9917 22.8429C34.4669 22.8429 35.1045 21.8531 35.1045 20.572C35.1045 19.2327 34.1147 18.3981 32.7366 18.3981C31.5914 18.3981 30.8344 18.9416 30.4851 19.5239H27.7949L28.7246 11.6426H37.5734L37.2784 14.2823H30.9489L30.6976 16.89C30.6976 16.89 31.3623 16.1068 33.3771 16.1068C35.8032 16.1068 37.9189 17.7372 37.9189 20.6108Z"
                fill="white"
            />
            <path
                d="M28.0458 9.95033C26.5465 4.10609 21.7892 0 11.9184 0H0L1.61778 10.9393C2.83477 20.8109 6.60992 23.9863 13.2917 25.1558C13.3228 25.1607 13.5343 25.1908 13.5372 24.9433C13.5382 24.854 13.4819 24.7773 13.4043 24.7443C8.88858 22.8422 8.37325 16.6078 7.40863 10.3502L6.66233 5.36383H13.4663C19.7395 5.36383 24.5813 5.26485 27.6489 10.1066C27.6946 10.1745 27.7654 10.225 27.8498 10.225C27.9683 10.225 28.0565 10.1289 28.0565 10.0105C28.0546 9.98917 28.0517 9.96975 28.0458 9.95033Z"
                fill="white"
            />
            <path
                d="M44.1929 11.4815H49.9187C51.355 11.4815 52.6293 11.7659 53.7424 12.3356C54.8555 12.9053 55.7193 13.7039 56.3336 14.7327C56.9479 15.7613 57.2555 16.9424 57.2555 18.2749C57.2555 19.6073 56.9479 20.7884 56.3336 21.8172C55.7193 22.8458 54.8545 23.6455 53.7424 24.2143C52.6293 24.7839 51.355 25.0683 49.9187 25.0683H44.1929V11.4815ZM49.8032 23.3796C50.9028 23.3796 51.8704 23.1661 52.705 22.7391C53.5396 22.3121 54.183 21.7133 54.6362 20.9437C55.0895 20.1741 55.3155 19.2842 55.3155 18.2749C55.3155 17.2656 55.0895 16.3757 54.6362 15.6061C54.183 14.8365 53.5396 14.2377 52.705 13.8107C51.8704 13.3837 50.9028 13.1702 49.8032 13.1702H46.1349V23.3796H49.8032Z"
                fill="white"
            />
            <path
                d="M59.4938 12.452C59.2542 12.2191 59.1348 11.9348 59.1348 11.598C59.1348 11.2613 59.2542 10.9739 59.4938 10.7343C59.7336 10.4945 60.0344 10.3752 60.3964 10.3752C60.7584 10.3752 61.0593 10.4888 61.2989 10.7148C61.5387 10.9409 61.658 11.2224 61.658 11.5592C61.658 11.9085 61.5377 12.2026 61.2989 12.4423C61.0593 12.682 60.7584 12.8013 60.3964 12.8013C60.0334 12.8013 59.7326 12.6849 59.4938 12.452ZM59.4648 14.7812H61.3281V25.0683H59.4648V14.7812Z"
                fill="white"
            />
            <path
                d="M71.2562 15.7323C72.0132 16.4311 72.3917 17.4724 72.3917 18.8573V25.0683H70.6254V23.7097C70.3149 24.1882 69.8713 24.554 69.2958 24.8063C68.7203 25.0587 68.0371 25.1848 67.2481 25.1848C66.0962 25.1848 65.1743 24.9063 64.4823 24.3503C63.7893 23.7942 63.4438 23.0624 63.4438 22.1569C63.4438 21.2515 63.7739 20.5236 64.4338 19.9733C65.0937 19.4231 66.1418 19.1484 67.5781 19.1484H70.5284V18.7797C70.5284 17.9771 70.2954 17.3628 69.8296 16.9358C69.3638 16.5088 68.6777 16.2953 67.7722 16.2953C67.1637 16.2953 66.5688 16.3952 65.9865 16.5961C65.4043 16.797 64.9122 17.0648 64.5114 17.4016L63.735 16.0041C64.2659 15.5771 64.8996 15.25 65.6372 15.0239C66.3747 14.7978 67.1579 14.6843 67.9857 14.6843C69.4084 14.6843 70.4993 15.0336 71.2562 15.7323ZM69.4026 23.273C69.9199 22.9557 70.2954 22.5063 70.5284 21.924V20.4878H67.6558C66.0768 20.4878 65.2878 21.0186 65.2878 22.0793C65.2878 22.5975 65.4887 23.0052 65.8894 23.3021C66.2903 23.6 66.8474 23.7485 67.5587 23.7485C68.27 23.7485 68.8844 23.5903 69.4026 23.273Z"
                fill="white"
            />
            <path
                d="M75.1367 10.6665H77.0001V25.0684H75.1367V10.6665Z"
                fill="white"
            />
            <path
                d="M81.6869 24.5055C80.8716 24.0523 80.234 23.4283 79.775 22.6325C79.3159 21.8367 79.0859 20.9342 79.0859 19.9248C79.0859 18.9155 79.3159 18.013 79.775 17.2172C80.234 16.4214 80.8716 15.8003 81.6869 15.3539C82.502 14.9074 83.4207 14.6843 84.4432 14.6843C85.4649 14.6843 86.3807 14.9074 87.1899 15.3539C87.9982 15.8003 88.6315 16.4214 89.0915 17.2172C89.5507 18.013 89.7807 18.9155 89.7807 19.9248C89.7807 20.9342 89.5507 21.8367 89.0915 22.6325C88.6324 23.4283 87.9982 24.0523 87.1899 24.5055C86.3799 24.9587 85.4649 25.1848 84.4432 25.1848C83.4199 25.1848 82.502 24.9587 81.6869 24.5055ZM86.219 23.0983C86.7432 22.7945 87.1532 22.3675 87.4515 21.8173C87.7482 21.267 87.8982 20.6362 87.8982 19.9248C87.8982 19.2135 87.7482 18.5827 87.4515 18.0324C87.1532 17.4822 86.7432 17.059 86.219 16.7611C85.6949 16.4632 85.1032 16.3147 84.4432 16.3147C83.7832 16.3147 83.1911 16.4632 82.667 16.7611C82.1429 17.059 81.7286 17.4822 81.4249 18.0324C81.121 18.5827 80.9687 19.2135 80.9687 19.9248C80.9687 20.6362 81.121 21.267 81.4249 21.8173C81.7286 22.3675 82.1429 22.7945 82.667 23.0983C83.1911 23.4021 83.7832 23.5544 84.4432 23.5544C85.1032 23.5544 85.6949 23.4021 86.219 23.0983Z"
                fill="white"
            />
            <path
                d="M101.85 14.7813V23.6709C101.85 25.4692 101.403 26.7988 100.51 27.6596C99.6173 28.5203 98.2839 28.9503 96.5114 28.9503C95.5414 28.9503 94.6156 28.8173 93.7364 28.5524C92.8564 28.2874 92.1448 27.909 91.6014 27.4169L92.4939 25.9806C92.9723 26.395 93.5648 26.7211 94.2698 26.9608C94.9756 27.2005 95.7031 27.3199 96.4539 27.3199C97.6573 27.3199 98.5473 27.0355 99.1223 26.4658C99.6981 25.8962 99.9864 25.0296 99.9864 23.865V23.0498C99.5464 23.5806 99.0056 23.9785 98.3656 24.2435C97.7248 24.5084 97.0289 24.6413 96.2789 24.6413C95.2956 24.6413 94.4056 24.4308 93.6098 24.0106C92.8139 23.5903 92.1906 23.0013 91.7373 22.2443C91.2839 21.4873 91.0581 20.6236 91.0581 19.6531C91.0581 18.6826 91.2839 17.8189 91.7373 17.0619C92.1906 16.3049 92.8139 15.7198 93.6098 15.3053C94.4056 14.891 95.2956 14.6843 96.2789 14.6843C97.0556 14.6843 97.7764 14.8269 98.4431 15.1113C99.1089 15.3956 99.6564 15.8168 100.083 16.3729V14.7813H101.85ZM98.3073 22.5839C98.8439 22.2996 99.2648 21.9017 99.5689 21.3903C99.8723 20.8788 100.025 20.3004 100.025 19.6531C100.025 18.6564 99.6948 17.8509 99.0348 17.2366C98.3748 16.6223 97.5281 16.3147 96.4923 16.3147C95.4439 16.3147 94.5906 16.6223 93.9306 17.2366C93.2706 17.8509 92.9406 18.6564 92.9406 19.6531C92.9406 20.3004 93.0931 20.8798 93.3964 21.3903C93.7006 21.9017 94.1214 22.2996 94.6581 22.5839C95.1948 22.8683 95.8064 23.0109 96.4923 23.0109C97.1648 23.0109 97.7706 22.8683 98.3073 22.5839Z"
                fill="white"
            />
        </svg>
    )
}
