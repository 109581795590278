import { useModal } from 'src/lib/providers/modal'
import styles from './style.module.css'
import Button from 'src/components/button'
import { useState } from 'react'

import { COURSE } from 'src/data'
import { useAuth } from 'src/lib/providers/auth'
import { MODAL } from 'src/components/layouts/layout'

const TermsPopup = () => {
    const { openModal, closeModal, context } = useModal()
    const questions = COURSE.terms.questions
    const { sendAnswer } = useAuth()

    const [currentQuestion, setCurrentQuestion] = useState(questions[0])
    const [selected, setSelected] = useState([])

    const handleChange = answer => {
        setSelected([answer])
    }

    const handleNext = () => {
        const answer = {
            surveyId: 'intro',
            questionId: currentQuestion.id,
            question: currentQuestion.title,
            answerId: '1',
            answer: 'согласен',
            isValid: true,
        }

        sendAnswer(answer).then(data => {
            window.termsAccepted = 1
            setSelected([])
            openModal(MODAL.QUIZ)
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.question}>
                    <h2>{currentQuestion.title}</h2>
                    <div className={styles.variants}>
                        {currentQuestion.answers.map((answer, index) => {
                            return (
                                <div key={index}>
                                    <label className={styles.answerLabel}>
                                        {answer.title}
                                        <input
                                            type="checkbox"
                                            value={answer.title}
                                            checked={selected.find(
                                                selectedAnswer =>
                                                    answer.title ===
                                                    selectedAnswer.title,
                                            )}
                                            onChange={() => {
                                                handleChange(answer)
                                            }}
                                        />
                                        <span
                                            className={
                                                styles.answerCheckmark
                                            }></span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>

                    <Button
                        fullWidth
                        disabled={!selected.length}
                        onClick={handleNext}>
                        Продолжить
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default TermsPopup
