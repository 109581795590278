export const MechanicIcon4 = () => {
    return (
        <svg
            width="57"
            height="71"
            viewBox="0 0 57 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_308)">
                <path
                    d="M52.1649 13.0739C44.1447 11.1606 36.2436 7.34124 30.4872 2.5059L28.2234 0.592529L26.0787 2.5059C20.6649 7.34124 12.4213 11.1606 4.28936 13.0739L0 14.0825V39.1566C0 48.4861 1.92128 53.5514 10.0457 58.7204L28.2234 70.3045L46.4011 58.7204C54.533 53.5514 56.4543 48.4935 56.4543 39.1566V14.0825L52.1649 13.0739ZM36.467 38.7116L38.6862 48.1376L29.5713 42.6718C28.7521 42.2046 27.583 42.2046 26.7638 42.6718L17.6489 48.1376L19.8681 38.7116C20.0989 37.7772 19.9872 36.7315 19.1681 36.0344L11.8106 29.5156L21.7372 28.4699C22.7872 28.3512 23.6064 27.7728 24.0755 26.8383L28.1638 17.8796L32.2521 26.8383C32.7213 27.7728 33.5404 28.3512 34.5904 28.4699L44.517 29.5156L37.1596 36.0344C36.3479 36.7315 36.2287 37.7772 36.467 38.7116Z"
                    fill="#5FAF2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_308">
                    <rect
                        width="56.4543"
                        height="69.7119"
                        fill="white"
                        transform="translate(0 0.592529)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
