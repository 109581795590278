import { useEffect } from 'react'
import styles from './style.module.css'
import { ArrowIcon } from 'src/assets/images/arrow-icon'
import { PassedIcon } from 'src/assets/images/passed-icon'
import { MODAL } from 'src/components/layouts/layout'
import { useAuth } from 'src/lib/providers/auth'
import { useModal } from 'src/lib/providers/modal'

export function getSurveyStatus(answers, surveyId, survey) {
    const isPassed =
        answers.filter(a => a.surveyId == surveyId).length ==
        survey.questions.length
    if (isPassed) return 2
    return 1
}

export default function Lessons({ data = [] }) {
    const { openModal, isOpen } = useModal()
    const { answers, loadAnswers, isAnswersLoaded } = useAuth()

    const shouldShowTermsPopup =
        isAnswersLoaded &&
        answers.filter(a => a.questionId == 'terms').length === 0 &&
        !window.termsAccepted

    if (shouldShowTermsPopup) {
        openModal(MODAL.TERMS)
    }

    useEffect(() => {
        if (!isOpen) loadAnswers()
    }, [isOpen])

    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <h2>Уроки</h2>
                    <div className={styles.grid}>
                        {data.map((lesson, i) => {
                            const { index, title } = lesson.content
                            const lessonId = `lesson-${i + 1}`
                            const status = getSurveyStatus(
                                answers,
                                lessonId,
                                lesson,
                            )
                            return (
                                <div
                                    key={'lesson' + i}
                                    onClick={() =>
                                        status != 2 &&
                                        openModal(MODAL.LESSON, {
                                            data: lesson,
                                            surveyId: lessonId,
                                        })
                                    }
                                    className={`${styles.lesson} ${status === 2 ? styles.passed : ''}`}>
                                    <div className={styles.lessonNum}>
                                        {index}
                                    </div>
                                    <div className={styles.lessonIcon}>
                                        {status === 2 ? (
                                            <PassedIcon />
                                        ) : (
                                            <ArrowIcon />
                                        )}
                                    </div>
                                    <div className={styles.lessonTitle}>
                                        {title}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
