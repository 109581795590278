import { Navigate, Outlet } from 'react-router-dom'

import Header from './header'
import Footer from './footer'
import Loader from './loader'
import { Suspense, useEffect } from 'react'

import Sign from 'src/components/modal/sign'

import Modal from 'src/components/modal'

import { useModal } from 'src/lib/providers/modal'
import { useAutologinDeprecated } from 'src/lib/hooks/auth'
import LessonPopup from 'src/components/modal/content-popup/lesson-popup'
import SuccessPopup from 'src/components/modal/success-popup'
import QuizPopup from 'src/components/modal/quiz-popup'
import CasePopup from 'src/components/modal/content-popup/case-popup'
import { useAuth } from 'src/lib/providers/auth'
import PathConstants from 'src/routes/path-constants'
import TermsPopup from 'src/components/modal/terms-popup'
import RegisterPopup from 'src/components/modal/register-popup'
import FeedbackPopup from 'src/components/modal/feedback-popup'

export const MODAL = {
    REGISTER_FULL: 'register_full',
    REGISTER: 'register',
    CASE: 'case',
    LESSON: 'lesson',
    SUCCESS: 'success',
    QUIZ: 'quiz',
    TERMS: 'terms',
    FEEDBACK: 'feedback',
}

function ModalContent() {
    const { modalType } = useModal()

    return (
        <>
            <Modal>
                {modalType === MODAL.REGISTER_FULL && <Sign />}
                {modalType === MODAL.REGISTER && <RegisterPopup />}
                {modalType === MODAL.LESSON && <LessonPopup />}
                {modalType === MODAL.CASE && <CasePopup />}
                {modalType === MODAL.SUCCESS && <SuccessPopup />}
                {modalType === MODAL.QUIZ && <QuizPopup />}
                {modalType === MODAL.TERMS && <TermsPopup />}
                {modalType === MODAL.FEEDBACK && <FeedbackPopup />}
            </Modal>
        </>
    )
}

export default function Layout() {
    const { init, isLoading } = useAuth()

    // useEffect(() => {
    //     init();
    // }, []);

    if (isLoading) return <></>

    return (
        <>
            <Header />
            <main>
                <Suspense fallback={<Loader />}>
                    <Outlet />
                </Suspense>
            </main>
            <Footer />

            <ModalContent />
        </>
    )
}
