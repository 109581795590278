import styles from './style.module.css'
import { Logo } from 'src/assets/images/logo'
import Button from 'src/components/button'
import { PinIcon } from 'src/assets/images/pin-icon'
import { PhoneIcon } from 'src/assets/images/phone-icon'
import { FaxIcon } from 'src/assets/images/fax-icon'
import SubscribeForm from 'src/components/subscribe-form'

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerWrapper}>
                <div>
                    <div className={styles.footerLogo}>
                        <Logo />
                    </div>
                    <div className={styles.footerConnect}>
                        <p>
                            Читайте больше про аналитику для бизнеса и бесплатно
                            обучайтесь продуктам Dialog X5 в блоге Connect
                        </p>
                        <a href="https://dialog.x5.ru/connect/" target="_blank">
                            <Button size="small">Перейти</Button>
                        </a>
                    </div>
                </div>
                <div>
                    <div className={styles.footerContacts}>
                        <div className={styles.footerContact}>
                            <div className={styles.footerIconWrapper}>
                                <PinIcon />
                            </div>
                            <p>
                                119049, Россия, Москва <br /> ул. Коровий Вал,
                                5, стр. 1
                            </p>
                        </div>
                        <div className={styles.footerContact}>
                            <div className={styles.footerIconWrapper}>
                                <PhoneIcon />
                            </div>
                            <p>
                                Тел. +7 (495) 662-88-88,
                                <br />
                                +7 (495) 789-95-95
                            </p>
                        </div>
                        <div className={styles.footerContact}>
                            <div className={styles.footerIconWrapper}>
                                <FaxIcon />
                            </div>
                            <p>
                                Факс. +7 (495) 662-88-88,
                                <br />
                                доб. 61-145
                            </p>
                        </div>
                    </div>
                    <div className={styles.footerForm}>
                        <p>
                            Подпишитесь на email-рассылку и получайте свежую
                            аналитику по категории и другие полезные материалы
                            еженедельно
                        </p>
                        <SubscribeForm />
                    </div>
                </div>
            </div>
            <hr noshade="" size="1" />
            <div className={styles.footerBottom}>
                <p>© 2015-2024 ПАО «Корпоративный центр ИКС 5»</p>
                <a href="#" target="_blank">
                    Политика конфиденциальности
                </a>
                <a href="#" target="_blank">
                    Уставные документы
                </a>
            </div>
        </footer>
    )
}
