import styles from './style.module.css'
import { PassedIcon } from 'src/assets/images/passed-icon'
import { ArrowIcon } from 'src/assets/images/arrow-icon'
import { MODAL } from 'src/components/layouts/layout'
import { useModal } from 'src/lib/providers/modal'
import { useAuth } from 'src/lib/providers/auth'
import { getSurveyStatus } from '../lessons'
import { useEffect } from 'react'

export default function Cases({ data = [] }) {
    const { openModal, isOpen } = useModal()
    const { answers, loadAnswers, isAnswersLoaded } = useAuth();

    useEffect(() => {
        if (!isOpen) loadAnswers()
    }, [isOpen])

    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <h2>Кейсы</h2>
                <div className={styles.wrapper}>
                    {data.map((caseItem, i) => {
                        const { index, title } = caseItem.content
                        const caseId = `case-${i + 1}`
                        const status = getSurveyStatus(answers, caseId, caseItem)
                        return (
                            <div key={"case"+i}
                                onClick={() =>
                                    openModal(MODAL.CASE, {
                                        data: caseItem,
                                        surveyId: caseId,
                                    })
                                }
                                className={`${styles.case} ${status === 2 ? styles.passed : ''}`}>
                                <div className={styles.caseNum}>{index}</div>

                                <div className={styles.caseTitle}>{title}</div>
                                <div className={styles.caseIcon}>
                                    {status === 2 ? (
                                        <PassedIcon />
                                    ) : (
                                        <ArrowIcon />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
