export const MechanicIcon3 = () => {
    return (
        <svg
            width="66"
            height="70"
            viewBox="0 0 66 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_317)">
                <path
                    d="M0 0V70H43.1674C47.3696 70 50.5173 69.1825 53.5505 66.5008C56.6983 63.819 59.2654 61.2519 61.9472 58.1041C64.6289 55.0709 65.3318 52.0378 65.3318 47.8356V0H0ZM26.8326 24.5023H47.8356V31.5008H26.8326V24.5023ZM18.6651 59.5023H10.4977V52.5038H18.6651V59.5023ZM18.6651 45.5053H10.4977V38.5069H18.6651V45.5053ZM18.6651 31.5008H10.4977V24.5023H18.6651V31.5008ZM19.5972 16.6175C19.0319 17.8706 17.7865 18.6728 16.4113 18.6728H12.1404C11.1013 18.6728 10.1157 18.2144 9.45099 17.4121L4.66819 11.6743H11.6667L13.9969 14.0046L16.8926 7.2506C17.2593 6.39489 18.1074 5.83715 19.0395 5.83715H24.5023L19.5972 16.6175ZM43.1674 59.5023H26.8326V52.5038H43.1674V59.5023ZM53.6728 45.5053H26.8326V38.5069H53.6651V45.5053H53.6728ZM56.0031 17.5038H26.8326V10.5053H56.0031V17.5038Z"
                    fill="#5FAF2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_317">
                    <rect width="65.3394" height="70" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
