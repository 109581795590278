import styles from './style.module.css'

const Button = ({
    variant = 'primary',
    fullWidth = false,
    size = '',
    iconLeft,
    iconRight,
    children,
    disabled = false,
    ...props
}) => {
    return (
        <button
            disabled={disabled}
            className={`${styles.button} ${styles[variant]} ${
                fullWidth ? styles.fullWidth : ''
            } ${size === 'small' ? styles.small : ''}`}
            {...props}>
            {iconLeft && <span className={styles.iconLeft}>{iconLeft}</span>}
            {children}
            {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
        </button>
    )
}

export default Button
