import { useDocumentsList } from 'src/lib/hooks/course'
import styles from './style.module.css'
import { MaterialIcon } from 'src/assets/images/material-icon'

export default function Materials() {
    // 0 - not stated, 1 - started ?, 2 passed
    // const items = [
    //     {
    //         title: 'Полезный материал',
    //     },
    //     {
    //         title: 'Полезный материал очень полезный материал очень длинный полезный',
    //     },
    //     {
    //         title: 'Еще материал',
    //     },
    //     {
    //         title: 'Полезный материал',
    //     },
    //     {
    //         title: 'Полезный материал',
    //     },
    //     {
    //         title: 'Полезный материал',
    //     },
    // ]

    const [{ items, isLoading }] = useDocumentsList({});

    if (isLoading || items.length == 0) return <div></div>;

    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <h2>Полезные материалы</h2>
                <div className={styles.wrapper}>
                    {items.map((item, i) => {
                        console.log(">>>>>", item);
                        return (
                            <a className={`${styles.material}`} key={"material"+i} href={`/v1/uploads/${item.url}`}>
                                <MaterialIcon />

                                <div className={styles.title}>{item.name}</div>
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
