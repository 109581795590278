import styles from './style.module.css'
import { useState } from 'react'
import Button from 'src/components/button'
import { CheckedIcon } from 'src/assets/images/checked-icon'
import { useAuth } from 'src/lib/providers/auth'
import isArraysEqual from 'src/lib/utils/isArraysEqual'

export default function Question({ data, handleNext, onSend }) {
    const [selected, setSelected] = useState([])
    const [answered, setAnswered] = useState(false)

    const question = data

    const handleChange = event => {
        const value = event.target.value
        if (event.target.type === 'checkbox') {
            setSelected(prev =>
                prev.includes(value)
                    ? prev.filter(item => item !== value)
                    : [...prev, value],
            )
        } else if (event.target.type === 'radio') {
            setSelected([value])
        }
    }

    const handleCheck = () => {
        onSend(question, selected)
        setAnswered(true)
    }

    const handleNextButton = () => {
        handleNext(question)
        setSelected([])
        setAnswered(false)
    }

    const getVariantClassName = variant => {
        if (answered) {
            if (variant.isValid) {
                return styles.valid
            } else if (selected.includes(variant.title) && !variant.isValid) {
                return styles.invalid
            } else {
                return styles.default
            }
        }
        return ''
    }

    const hasSeveralCorrectAnswers = question.type === 'multiple'

    const inputType = hasSeveralCorrectAnswers ? 'checkbox' : 'radio'

    const renderHint = () => {
        if (answered) {
            const currentAnswer = question.answers.find(answer => {
                return selected.includes(answer.title)
            })
            if (hasSeveralCorrectAnswers && question.hint) {
                const validAnswers = question.answers
                    .filter(a => a.isValid)
                    .map(a => a.title)
                const userAnswers = selected

                const isValid = isArraysEqual(validAnswers, selected)

                if (!isValid) {
                    return question.hint
                }

                return null
            }
            if (currentAnswer && currentAnswer.hint) {
                return currentAnswer.hint
            } else {
                return null
            }
        }
        return null
    }

    if (question.alreadyAnswered) {
        return (
            <div className={`${styles.question} ${styles.alreadyAnswered}`}>
                <div className={styles.title}>{question.title}</div>
                <CheckedIcon />
            </div>
        )
    }

    return (
        <div className={styles.question}>
            <div className={styles.title}>{question.title}</div>
            <div className={styles.variantOption}>
                {hasSeveralCorrectAnswers
                    ? 'предполагается несколько правильных ответов'
                    : 'выберите верный ответ'}
            </div>
            <hr noshade="" size="1" />

            <div
                className={`${styles.variants} ${answered ? styles.answered : ''}`}>
                {question.answers.map((answer, index) => {
                    return (
                        <div
                            key={index}
                            className={getVariantClassName(answer)}>
                            <label
                                className={`${styles.answerLabel} ${selected.includes(answer.title) ? styles.answerLabelSelected : ''}`}>
                                {answer.title}
                                <input
                                    type={inputType}
                                    value={answer.title}
                                    checked={selected.includes(answer.title)}
                                    onChange={handleChange}
                                    disabled={answered}
                                />
                                <span className={styles.answerCheckmark}></span>
                            </label>
                        </div>
                    )
                })}
            </div>

            {answered && (
                <div className={styles.correctText}>{renderHint()}</div>
            )}

            {!answered && (
                <Button
                    disabled={selected.length === 0}
                    fullWidth
                    onClick={handleCheck}>
                    Проверить
                </Button>
            )}
            {answered && (
                <Button fullWidth onClick={handleNextButton}>
                    Дальше
                </Button>
            )}
        </div>
    )
}
