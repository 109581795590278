export const FaxIcon = () => {
    return (
        <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_215)">
                <path
                    d="M8 0V1V4H6C6 2.90694 5.09306 2 4 2H2C0.90694 2 0 2.90694 0 4V13C0 14.0931 0.90694 15 2 15V16C2 17.645 3.35503 19 5 19H8C9.645 19 11 17.645 11 16V15H18C19.0931 15 20 14.0931 20 13V6C20 4.90694 19.0931 4 18 4H16V0H8ZM10 2H14V4H10V2ZM2 4H4V13H2V4ZM6 6H9H16H18V13H6V6ZM9 8V10H11V8H9ZM13 8V10H15V8H13ZM5 14.7188C5.29624 14.8935 5.63576 15 6 15H9V16C9 16.565 8.565 17 8 17H5C4.43497 17 4 16.565 4 16V15C4.36424 15 4.70376 14.8935 5 14.7188Z"
                    fill="#B5C7CB"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_215">
                    <rect width="20" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
