export const PhoneIcon = () => {
    return (
        <svg
            width="14"
            height="22"
            viewBox="0 0 14 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_213)">
                <path
                    d="M11.5 0H2.5C1.12 0 0 1.12 0 2.5V19.5C0 20.88 1.12 22 2.5 22H11.5C12.88 22 14 20.88 14 19.5V2.5C14 1.12 12.88 0 11.5 0ZM7 20.125C6.378 20.125 5.875 19.622 5.875 19C5.875 18.378 6.378 17.875 7 17.875C7.622 17.875 8.125 18.378 8.125 19C8.125 19.622 7.622 20.125 7 20.125ZM12 17H2V3H12V17Z"
                    fill="#B5C7CB"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_213">
                    <rect width="14" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
