export const PinIcon = () => {
    return (
        <svg
            width="14"
            height="21"
            viewBox="0 0 14 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_211)">
                <path
                    d="M7 0.00976562C3.144 0.00976562 0.0078125 3.14791 0.0078125 7.00391C0.0078125 11.4869 5.9745 18.7692 6.2285 19.0762L7 20.0117L7.7715 19.0762C8.0255 18.7682 13.9922 11.4869 13.9922 7.00391C13.9922 3.14691 10.856 0.00976562 7 0.00976562ZM7 2.00977C9.753 2.00977 11.9922 4.25091 11.9922 7.00391C11.9922 9.7089 8.878 14.3612 7 16.8262C5.122 14.3632 2.00781 9.7129 2.00781 7.00391C2.00781 4.25091 4.247 2.00977 7 2.00977ZM7 4.5C5.619 4.5 4.5 5.619 4.5 7C4.5 8.381 5.619 9.5 7 9.5C8.381 9.5 9.5 8.381 9.5 7C9.5 5.619 8.381 4.5 7 4.5Z"
                    fill="#B5C7CB"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_211">
                    <rect width="14" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
