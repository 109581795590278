import styles from './style.module.css'
import { UserIcon } from 'src/assets/images/user-icon'
import Keycloak from 'keycloak-js';
import { useAuth } from 'src/lib/providers/auth';

// const keycloak = new Keycloak({
//     url: config.keycloakAuth,
//     realm: "dialog",
//     clientId: "x5academy"
// });

export default function HeaderAuth() {
    const { login, userId } = useAuth();

    return <div className={styles.loginButton} onClick={login}>
        <UserIcon />
        <span>Войти</span>
    </div>;
}
