import styles from './style.module.css'
import { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'

export default function ImageWithLightbox({ smallImage, largeImage }) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <div className={styles.wrapper} onClick={() => setOpen(true)}>
                <img src={smallImage} />
                <span>нажмите для увеличения</span>
            </div>

            <Lightbox
                open={open}
                carousel={{ finite: true }}
                close={() => setOpen(false)}
                slides={[{ src: largeImage }]}
            />
        </>
    )
}
