export const MaterialIcon = () => {
    return (
        <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_288)">
                <path
                    d="M0 0V24H14.8C16.24 24 17.32 23.72 18.36 22.8C19.44 21.88 20.32 21 21.24 19.92C22.16 18.88 22.4 17.84 22.4 16.4V0H0ZM12.8 20.4H2.8V18H12.8V20.4ZM19.6 15.6H2.8V13.2H19.6V15.6ZM19.6 10.8H2.8V8.4H19.6V10.8ZM19.6 6H2.8V3.6H19.6V6Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_288">
                    <rect width="22.4" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
