import { useEffect, useState } from "react";
import { api } from "../providers/auth";

export const useDocumentsList = ({ limit=10, offset=0, ordering=""}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);

  const request = (params) => {
    const opts = {
      params
    };

    return api.get("/documents/", opts);
  }

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await request({ limit, offset, ordering });
        console.log("Setting docs", d);
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [limit, offset, ordering]);

  return [{ items: data || [], isLoading, error }];
}