import styles from './style.module.css'

const FormInput = ({
    placeholder,
    fullWidth = false,
    size = '',
    errorText,
    isValid,
    ...otherProps
}) => {
    return (
        <div
            className={`${styles.formInput} ${fullWidth ? styles.fullWidth : ''} ${isValid ? styles.valid : ''} ${errorText ? styles.hasError : ''}`}>
            <input
                className={`${styles.formInput__input} ${size === 'small' ? styles.small : ''}`}
                placeholder={placeholder}
                {...otherProps}
            />

            <label
                className={styles.formInput__label}
                data-content={placeholder}>
                <span className={styles.hiddenVisually}>{placeholder}</span>
            </label>
            {errorText && <div className={styles.error}>{errorText}</div>}
        </div>
    )
}

export default FormInput
