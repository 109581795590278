import FormInput from '../components/form-input'
import Hero from 'src/components/hero'
import AboutCourse from '../components/about-course'
import { useAuth } from 'src/lib/providers/auth'
import { Navigate, useNavigate } from 'react-router';
import PathConstants from 'src/routes/path-constants';

export default function Home() {
    const { userId } = useAuth();
    // const navigate = useNavigate();
    console.log("Navigate?", userId);
    console.log("RENDER HOME");
    if (userId) return <Navigate to={PathConstants.COURSE} />;

    return (
        <div>
            <Hero />
            <AboutCourse />
        </div>
    )
}
