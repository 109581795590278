import styles from './style.module.css'
import Button from 'src/components/button'
import { COURSE } from 'src/data'
import Rating from 'src/components/StarRating'
import { useState, useMemo, useCallback } from 'react'
import { useAuth } from 'src/lib/providers/auth'
import { useModal } from 'src/lib/providers/modal'

const FeedbackPopup = () => {
    const { answers, sendAnswer, isAnswersLoaded } = useAuth()
    const { closeModal } = useModal()

    const OUTRO = COURSE.outro[0]

    const { title, description } = OUTRO.content
    const ratingQuestions = useMemo(
        () => OUTRO.questions.filter(q => q.type === 'single'),
        [],
    )
    const commentQuestion = useMemo(
        () => OUTRO.questions.find(q => q.type === 'text'),
        [],
    )
    const userAnswers = useMemo(
        () => answers?.filter(a => a.surveyId === 'outro') || [],
        [answers],
    )

    const currentRating = useMemo(() => {
        return ratingQuestions.reduce((acc, obj) => {
            acc[obj.id] =
                userAnswers.findLast(a => a.questionId === obj.id)?.answer || 0
            return acc
        }, {})
    }, [ratingQuestions, userAnswers])

    const [feedback, setFeedback] = useState({
        rating: currentRating,
        comment:
            userAnswers.findLast(a => a.questionId === commentQuestion?.id)
                ?.answer || 0,
    })

    const handleSend = useCallback(() => {
        if (feedback.comment.length) {
            const answer = {
                surveyId: 'outro',
                questionId: commentQuestion.id,
                question: commentQuestion.title,
                answerId: '1',
                answer: feedback.comment,
                isValid: true,
            }
            sendAnswer(answer)
            closeModal()
        }
    }, [feedback.comment, commentQuestion, sendAnswer])

    const onRatingChange = useCallback(
        (question, newRating) => {
            setFeedback(prev => ({
                ...prev,
                rating: { ...prev.rating, [question.id]: newRating },
            }))

            const answer = {
                surveyId: 'outro',
                questionId: question.id,
                question: question.title,
                answerId: newRating.toString(),
                answer: newRating.toString(),
                isValid: true,
            }
            sendAnswer(answer)
        },
        [sendAnswer],
    )

    if (!isAnswersLoaded) {
        return null
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>{title}</h2>
                <div className={styles.title}>{description}</div>
                <div>
                    {ratingQuestions.map(question => (
                        <div key={question.id} className={styles.question}>
                            <div className={styles.questionTitle}>
                                {question.title}
                            </div>
                            <Rating
                                initialRating={feedback.rating[question.id]}
                                onRatingChange={starIndex =>
                                    onRatingChange(question, starIndex)
                                }
                            />
                        </div>
                    ))}
                </div>

                {commentQuestion && (
                    <div className={styles.comment}>
                        <div>{commentQuestion.title}</div>
                        <textarea
                            placeholder="Текст комментария"
                            value={feedback.comment}
                            onChange={e =>
                                setFeedback(prev => ({
                                    ...prev,
                                    comment: e.target.value,
                                }))
                            }
                        />
                    </div>
                )}

                <Button fullWidth onClick={handleSend}>
                    Отправить
                </Button>
            </div>
        </div>
    )
}

export default FeedbackPopup
