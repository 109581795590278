import Mechanics from 'src/components/mechanics'
import Lessons from 'src/components/lessons'
import Cases from 'src/components/cases'
import Materials from 'src/components/materials'
import HeroCourse from '../components/hero/hero-course'
import { COURSE } from 'src/data'
import { useAuth } from 'src/lib/providers/auth'
import { Navigate } from 'react-router'
import PathConstants from 'src/routes/path-constants'
import Feedback from '../components/feedback'

export default function Course() {
    const { userId } = useAuth()

    if (!userId) return <Navigate to={PathConstants.HOME} />

    const { cases, lessons } = COURSE
    return (
        <div>
            <HeroCourse />
            <Mechanics />
            <Lessons data={lessons} />
            <Cases data={cases} />
            <Materials />
            <Feedback />
        </div>
    )
}
