import PathConstants from './path-constants'
import Home from '../pages/home'
import Course from '../pages/course'


const routes = [
    { path: PathConstants.HOME, element: <Home /> },
    { path: PathConstants.COURSE, element: <Course /> },

]
export default routes
