import styles from './style.module.css'

import heroMobileImage from 'src/assets/images/KV_course.svg'

export default function HeroCourse() {
    return (
        <div className={`${styles.hero} ${styles.heroCourse}`}>
            <div className={styles.heroContainer}>
                <div className={styles.heroBadge}>АКАДЕМИЯ DIALOG&nbsp;X5</div>
                <h1>
                    Аналитика для
                    <br />
                    роста бизнеса
                </h1>

                {window.innerWidth < 768 && (
                    <div className={styles.mobileImageContainer}>
                        <img src={heroMobileImage} />
                    </div>
                )}
                <div className={styles.description}>
                    <p>
                        Курс состоит из обучающих роликов и&nbsp;кейсов
                        для&nbsp;самостоятельного анализа.
                    </p>
                    <p>
                        Мы расскажем, какие данные хранит аналитика, как ими
                        пользоваться в&nbsp;разных ситуациях и&nbsp;проверим
                        ваши знания.
                    </p>
                </div>
            </div>
        </div>
    )
}
