import styles from './style.module.css'
import { Logo } from 'src/assets/images/logo'
import HeaderAuth from 'src/components/header-auth'
import { useAuth } from 'src/lib/providers/auth'

export default function Header() {
    const { userId } = useAuth()

    console.log('Got userId', userId)

    return (
        <header className={styles.header}>
            <div className={styles.headerLogo}>
                <Logo />
            </div>
            <div>{!userId && <HeaderAuth />}</div>
        </header>
    )
}
