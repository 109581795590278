export const MechanicIcon2 = () => {
    return (
        <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_314)">
                <path
                    d="M65.3394 0H4.66819C2.10107 0 0 2.10107 0 4.66819V51.3349C0 53.902 2.10107 56.0031 4.66819 56.0031H23.3333L23.0965 59.0362C22.745 63.7044 21.5761 64.5219 16.7933 64.8734L10.4901 65.3394V70.0076H59.4947V65.3394L53.1914 64.8734C48.4086 64.5219 47.2397 63.7044 46.8882 59.0362L46.6514 56.0031H65.3165C67.8836 56.0031 69.9847 53.902 69.9847 51.3349V4.66819C70 2.10107 67.9066 0 65.3394 0ZM63.0015 45.0775H6.99847V6.99847H63.0015V45.0775Z"
                    fill="#5FAF2D"
                />
                <path
                    d="M28.5364 35.7334V17.8858C28.5364 16.7092 29.8123 15.9757 30.8285 16.564L46.2847 25.4878C47.3008 26.0761 47.3008 27.5431 46.2847 28.1314L30.8285 37.0552C29.8123 37.6435 28.5364 36.91 28.5364 35.7334Z"
                    fill="#5FAF2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_314">
                    <rect width="70" height="70" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
