import { useState } from 'react'
import styles from './style.module.css'
import FormInput from 'src/components/form-input'
import Button from 'src/components/button'

const Sign = () => {
    const [step, setStep] = useState(0)

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        surname: '',
        company: '',
        position: '',
    })

    const [errors, setErrors] = useState({
        email: '',
        name: '',
        surname: '',
        company: '',
        position: '',
    })

    const [finalStep, setFinalStep] = useState({
        heading: 'Спасибо за регистрацию',
        text: 'В ближайшее время с вами свяжется наш менеджер, чтобы предоставить доступ к обучающему курсу и рассказать все подробности.',
    })

    const validateField = (name, value) => {
        let error = ''

        if (!value.trim()) {
            error = 'Обязательное поле'
        } else {
            switch (name) {
                case 'email':
                    if (!/\S+@\S+\.\S+/.test(value)) {
                        error = 'Неверный формат email'
                    }
                    break

                default:
                    break
            }
        }

        return error
    }

    const handleChange = e => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleBlur = e => {
        const { name, value } = e.target
        setErrors({
            ...errors,
            [name]: validateField(name, value),
        })
    }

    const handleStep1 = () => {
        // do something

        const isValidEmail = !errors.email && formData.email

        if (isValidEmail) {
            setStep(1)
        }
    }

    const handleStep2 = () => {
        // do something
        const newErrors = {}
        let hasErrors = false
        Object.keys(formData).forEach(key => {
            const errorText = validateField(key, formData[key])
            if (errorText) {
                hasErrors = true
            }
            newErrors[key] = errorText
        })

        setErrors(newErrors)
        if (!hasErrors) {
            setFinalStep({
                heading: 'Регистрация',
                text: `Для завершения регистрации вы будете перенаправлены на портал. Переадресация произойдет через 5 секунд, если этого не случится <a href="#">используйте ссылку</a>.`,
            })
            setStep(2)
        }
    }

    return (
        <div>
            {step === 0 && (
                <div className={styles.form}>
                    <h2>Регистрация</h2>
                    <FormInput
                        value={formData.email}
                        name="email"
                        fullWidth
                        type="text"
                        placeholder="E-mail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorText={errors.email}
                        isValid={!errors.email && formData.email.length > 0}
                    />
                    <div className={styles.formFooter}>
                        <div className={styles.formTerms}>
                            Нажимая «Продолжить», я соглашаюсь с{' '}
                            <a href="#">
                                Политикой обработки и хранения данных
                            </a>
                        </div>
                        <Button onClick={handleStep1}>Продолжить</Button>
                    </div>
                </div>
            )}
            {step === 1 && (
                <div className={styles.form}>
                    <h2>Регистрация</h2>
                    <div className={styles.formGrid}>
                        <FormInput
                            fullWidth
                            name="surname"
                            type="text"
                            placeholder="Фамилия"
                            errorText={errors.surname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isValid={
                                !errors.surname && formData.surname.length > 0
                            }
                        />
                        <FormInput
                            name="name"
                            fullWidth
                            type="text"
                            placeholder="Имя"
                            errorText={errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isValid={!errors.name && formData.name.length > 0}
                        />
                        <FormInput
                            fullWidth
                            name="company"
                            type="text"
                            placeholder="Компания"
                            errorText={errors.company}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isValid={
                                !errors.company && formData.company.length > 0
                            }
                        />
                        <FormInput
                            fullWidth
                            name="position"
                            type="text"
                            placeholder="Должность"
                            onBlur={handleBlur}
                            errorText={errors.position}
                            onChange={handleChange}
                            isValid={
                                !errors.position && formData.position.length > 0
                            }
                        />
                    </div>

                    <div
                        className={`${styles.formFooter} ${styles.formFooterButtons}`}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setStep(0)
                            }}>
                            Назад
                        </Button>
                        <Button onClick={handleStep2}>
                            Зарегистрироваться
                        </Button>
                    </div>
                </div>
            )}
            {step === 2 && (
                <div className={`${styles.form} ${styles.finalStep}`}>
                    <h2>{finalStep.heading}</h2>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: finalStep.text,
                        }}></div>
                </div>
            )}
        </div>
    )
}

export default Sign
