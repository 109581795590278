export const PassedIcon = () => {
    return (
        <svg
            width="41"
            height="50"
            viewBox="0 0 41 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_310)">
                <path
                    d="M37.2606 8.91529C31.5319 7.54859 25.8883 4.82051 21.7766 1.36669L20.1596 0L18.6277 1.36669C14.7606 4.82051 8.87234 7.54859 3.06383 8.91529L0 9.63572V27.5457C0 34.2097 1.37234 37.8277 7.17553 41.5199L20.1596 49.7942L33.1436 41.5199C38.9521 37.8277 40.3245 34.215 40.3245 27.5457V9.63572L37.2606 8.91529ZM26.0479 27.2279L27.633 33.9607L21.1223 30.0567C20.5372 29.7229 19.7021 29.7229 19.117 30.0567L12.6064 33.9607L14.1915 27.2279C14.3564 26.5605 14.2766 25.8135 13.6915 25.3156L8.43617 20.6593L15.5266 19.9124C16.2766 19.8276 16.8617 19.4145 17.1968 18.747L20.117 12.3479L23.0372 18.747C23.3723 19.4145 23.9574 19.8276 24.7074 19.9124L31.7979 20.6593L26.5426 25.3156C25.9628 25.8135 25.8777 26.5605 26.0479 27.2279Z"
                    fill="#5FAF2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_310">
                    <rect width="40.3245" height="49.7942" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
