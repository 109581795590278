export const ArrowIcon = () => {
    return (
        <svg
            width="72"
            height="71"
            viewBox="0 0 72 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_113_277)">
                <path
                    d="M53.6188 53.033C63.3819 43.2699 63.3819 27.4408 53.6188 17.6777C43.8557 7.91456 28.0266 7.91456 18.2635 17.6777C8.50039 27.4408 8.50039 43.2699 18.2635 53.033C28.0266 62.7961 43.8557 62.7961 53.6188 53.033Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.2046 27.2529L36.6777 25.7798L45.5163 34.6183L46.2531 35.3552L36.6777 44.9306L35.2046 43.4575L42.2652 36.3968L28.4622 36.3968L28.4622 34.3135L42.2652 34.3135L35.2046 27.2529Z"
                    fill="#5FAF2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_113_277">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                        transform="translate(35.9412) rotate(45)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
