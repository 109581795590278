import styles from './style.module.css'
import Button from 'src/components/button'
import FormInput from 'src/components/form-input'
import { useState } from 'react'
import { CloseIcon } from 'src/assets/images/close-icon'

const SubscribeForm = () => {
    const [isFormSubscribed, setIsFormSubscribed] = useState(false)
    const handleSubmit = e => {
        e.preventDefault()
        setIsFormSubscribed(true)
    }
    return (
        <>
            {isFormSubscribed && (
                <div className={styles.toast}>
                    <span>
                        Спасибо! Мы получили ваш запрос. Не пропустите нашу
                        рассылку - обещаем много интересного!
                    </span>
                    <div
                        className={styles.closeToast}
                        onClick={() => {
                            setIsFormSubscribed(false)
                        }}>
                        <CloseIcon />
                    </div>
                </div>
            )}
            <div className={styles.subscribeForm}>
                <form>
                    <FormInput type="text" placeholder="E-mail" size="small" />
                    <Button size="small" onClick={handleSubmit}>
                        Подписаться
                    </Button>
                </form>
            </div>
        </>
    )
}

export default SubscribeForm
