export const Logo = () => {
    return (
        <svg
            width="123"
            height="36"
            viewBox="0 0 123 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.6481 30.2764L25.2708 24.6631L21.9634 30.2764H18.0039L23.0919 21.8204L17.9072 13.9712H22.0438L25.3652 19.2118L28.5328 13.9712H32.4923L27.3915 22.0766L32.6321 30.2752H28.6481V30.2764Z"
                fill="currentColor"
            />
            <path
                d="M45.5027 24.733C45.5027 28.3199 42.8009 30.5559 39.2606 30.5559C36.1395 30.5559 33.5111 28.8323 33.2549 25.0824H36.6321C36.8651 26.7128 38.1694 27.4115 39.5901 27.4115C41.3603 27.4115 42.1254 26.2237 42.1254 24.6864C42.1254 23.0793 40.9376 22.0778 39.2839 22.0778C37.9097 22.0778 37.0013 22.73 36.5821 23.4287H33.3539L34.4695 13.9712H45.0881L44.7341 17.1388H37.1387L36.8371 20.268C36.8371 20.268 37.6348 19.3282 40.0525 19.3282C42.9639 19.3282 45.5027 21.2847 45.5027 24.733Z"
                fill="currentColor"
            />
            <path
                d="M33.655 11.9404C31.8558 4.92731 26.147 0 14.3021 0H0L1.94134 13.1271C3.40172 24.9731 7.93191 28.7836 15.95 30.1869C15.9873 30.1928 16.2411 30.2289 16.2446 29.9319C16.2458 29.8248 16.1783 29.7328 16.0851 29.6932C10.6663 27.4106 10.0479 19.9294 8.89036 12.4202L7.9948 6.4366H16.1596C23.6874 6.4366 29.4975 6.31782 33.1787 12.1279C33.2335 12.2094 33.3185 12.27 33.4198 12.27C33.5619 12.27 33.6678 12.1547 33.6678 12.0126C33.6655 11.987 33.662 11.9637 33.655 11.9404Z"
                fill="#5FAF2D"
            />
            <path
                d="M53.0312 13.7778H59.9022C61.6258 13.7778 63.155 14.1191 64.4907 14.8027C65.8265 15.4863 66.8629 16.4447 67.6001 17.6792C68.3372 18.9136 68.7064 20.3309 68.7064 21.9299C68.7064 23.5288 68.3372 24.9461 67.6001 26.1806C66.8629 27.415 65.8253 28.3746 64.4907 29.0571C63.155 29.7407 61.6258 30.0819 59.9022 30.0819H53.0312V13.7778ZM59.7636 28.0555C61.0831 28.0555 62.2443 27.7993 63.2458 27.2869C64.2474 26.7745 65.0195 26.0559 65.5633 25.1324C66.1072 24.2089 66.3785 23.141 66.3785 21.9299C66.3785 20.7187 66.1072 19.6508 65.5633 18.7273C65.0195 17.8038 64.2474 17.0852 63.2458 16.5728C62.2443 16.0604 61.0831 15.8042 59.7636 15.8042H55.3616V28.0555H59.7636Z"
                fill="currentColor"
            />
            <path
                d="M71.3928 14.9424C71.1052 14.6629 70.9619 14.3217 70.9619 13.9176C70.9619 13.5135 71.1052 13.1687 71.3928 12.8811C71.6805 12.5934 72.0415 12.4502 72.4759 12.4502C72.9103 12.4502 73.2713 12.5865 73.5589 12.8578C73.8466 13.1291 73.9898 13.4669 73.9898 13.871C73.9898 14.2902 73.8454 14.6431 73.5589 14.9307C73.2713 15.2184 72.9103 15.3616 72.4759 15.3616C72.0403 15.3616 71.6793 15.2219 71.3928 14.9424ZM71.3579 17.7374H73.5939V30.0819H71.3579V17.7374Z"
                fill="currentColor"
            />
            <path
                d="M85.5076 18.8788C86.416 19.7173 86.8702 20.9669 86.8702 22.6288V30.082H84.7507V28.4516C84.378 29.0258 83.8458 29.4648 83.1552 29.7676C82.4646 30.0704 81.6447 30.2218 80.6979 30.2218C79.3156 30.2218 78.2093 29.8876 77.3789 29.2203C76.5474 28.553 76.1328 27.6749 76.1328 26.5883C76.1328 25.5018 76.5288 24.6283 77.3207 23.968C78.1126 23.3077 79.3703 22.9781 81.0939 22.9781H84.6342V22.5356C84.6342 21.5725 84.3547 20.8353 83.7957 20.3229C83.2367 19.8105 82.4134 19.5543 81.3268 19.5543C80.5966 19.5543 79.8827 19.6742 79.184 19.9153C78.4853 20.1564 77.8948 20.4778 77.4138 20.8819L76.4822 19.2049C77.1192 18.6925 77.8797 18.3 78.7648 18.0287C79.6498 17.7573 80.5896 17.6211 81.583 17.6211C83.2903 17.6211 84.5993 18.0403 85.5076 18.8788ZM83.2833 27.9276C83.904 27.5468 84.3547 27.0076 84.6342 26.3088V24.5853H81.1871C79.2923 24.5853 78.3455 25.2223 78.3455 26.4952C78.3455 27.117 78.5866 27.6062 79.0675 27.9625C79.5485 28.32 80.217 28.4982 81.0706 28.4982C81.9242 28.4982 82.6614 28.3084 83.2833 27.9276Z"
                fill="currentColor"
            />
            <path
                d="M90.1641 12.7998H92.4001V30.0821H90.1641V12.7998Z"
                fill="currentColor"
            />
            <path
                d="M98.0244 29.4066C97.0461 28.8627 96.281 28.1139 95.7302 27.159C95.1793 26.204 94.9033 25.121 94.9033 23.9098C94.9033 22.6986 95.1793 21.6156 95.7302 20.6606C96.281 19.7057 97.0461 18.9604 98.0244 18.4247C99.0026 17.8889 100.105 17.6211 101.332 17.6211C102.558 17.6211 103.657 17.8889 104.628 18.4247C105.598 18.9604 106.358 19.7057 106.91 20.6606C107.461 21.6156 107.737 22.6986 107.737 23.9098C107.737 25.121 107.461 26.204 106.91 27.159C106.359 28.1139 105.598 28.8627 104.628 29.4066C103.656 29.9504 102.558 30.2218 101.332 30.2218C100.104 30.2218 99.0026 29.9504 98.0244 29.4066ZM103.463 27.718C104.092 27.3534 104.584 26.841 104.942 26.1807C105.298 25.5204 105.478 24.7634 105.478 23.9098C105.478 23.0562 105.298 22.2992 104.942 21.6389C104.584 20.9786 104.092 20.4708 103.463 20.1133C102.834 19.7558 102.124 19.5776 101.332 19.5776C100.54 19.5776 99.8295 19.7558 99.2006 20.1133C98.5717 20.4708 98.0745 20.9786 97.71 21.6389C97.3454 22.2992 97.1626 23.0562 97.1626 23.9098C97.1626 24.7634 97.3454 25.5204 97.71 26.1807C98.0745 26.841 98.5717 27.3534 99.2006 27.718C99.8295 28.0825 100.54 28.2653 101.332 28.2653C102.124 28.2653 102.834 28.0825 103.463 27.718Z"
                fill="currentColor"
            />
            <path
                d="M122.22 17.7376V28.4051C122.22 30.563 121.684 32.1585 120.612 33.1915C119.541 34.2244 117.941 34.7403 115.814 34.7403C114.65 34.7403 113.539 34.5808 112.484 34.2629C111.428 33.9449 110.574 33.4908 109.922 32.9003L110.993 31.1767C111.567 31.674 112.278 32.0653 113.124 32.353C113.971 32.6406 114.844 32.7839 115.745 32.7839C117.189 32.7839 118.257 32.4426 118.947 31.759C119.638 31.0754 119.984 30.0355 119.984 28.638V27.6597C119.456 28.2967 118.807 28.7742 118.039 29.0922C117.27 29.4101 116.435 29.5696 115.535 29.5696C114.355 29.5696 113.287 29.3169 112.332 28.8127C111.377 28.3084 110.629 27.6015 110.085 26.6931C109.541 25.7848 109.27 24.7483 109.27 23.5837C109.27 22.4191 109.541 21.3827 110.085 20.4743C110.629 19.5659 111.377 18.8637 112.332 18.3664C113.287 17.8692 114.355 17.6211 115.535 17.6211C116.467 17.6211 117.332 17.7923 118.132 18.1335C118.931 18.4747 119.588 18.9802 120.1 19.6475V17.7376H122.22ZM117.969 27.1007C118.613 26.7595 119.118 26.282 119.483 25.6683C119.847 25.0546 120.03 24.3605 120.03 23.5837C120.03 22.3877 119.634 21.4211 118.842 20.6839C118.05 19.9468 117.034 19.5776 115.791 19.5776C114.533 19.5776 113.509 19.9468 112.717 20.6839C111.925 21.4211 111.529 22.3877 111.529 23.5837C111.529 24.3605 111.712 25.0557 112.076 25.6683C112.441 26.282 112.946 26.7595 113.59 27.1007C114.234 27.442 114.968 27.6131 115.791 27.6131C116.598 27.6131 117.325 27.442 117.969 27.1007Z"
                fill="currentColor"
            />
        </svg>
    )
}
