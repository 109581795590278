import { useState } from 'react'
import styles from './style.module.css'

const Rating = ({ initialRating = 0, onRatingChange }) => {
    const [rating, setRating] = useState(initialRating)

    const handleClick = index => {
        setRating(index)
        if (onRatingChange) {
            onRatingChange(index)
        }
    }

    return (
        <div className={styles.rating}>
            {[...Array(5)].map((star, index) => {
                index += 1
                return (
                    <button
                        type="button"
                        key={index}
                        className={index <= rating ? styles.active : ''}
                        onClick={() => handleClick(index)}>
                        <svg
                            width="32"
                            height="30"
                            viewBox="0 0 32 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M24.2581 20.069C24.2581 19.3966 24.5677 18.8793 25.0323 18.4138L32 11.8966L22.4 10.5517C21.5226 10.4483 20.8 9.93104 20.4387 9.15517L16 0L11.5613 9.15517C11.2 9.93104 10.4774 10.4483 9.6 10.5517L0 11.8966L6.96774 18.4138C7.43226 18.8793 7.74193 19.3966 7.74193 20.069C7.74193 20.3276 7.69032 20.6897 7.63871 20.9483L5.67742 30L14.7097 24.6724C15.1226 24.4138 15.5871 24.3103 16 24.3103C16.4129 24.3103 16.8774 24.4138 17.2903 24.6724L26.3226 30L24.3613 20.9483C24.3097 20.6897 24.2581 20.3276 24.2581 20.069Z"
                                fill="currentColor"
                            />
                        </svg>
                    </button>
                )
            })}
        </div>
    )
}

export default Rating
