import styles from './style.module.css'

const VideoKinescope = ({ videoId }) => {
    return (
        <div className={styles.container}>
            <iframe
                src={`https://kinescope.io/embed/${videoId}`}
                allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;"
                frameBorder="0"
                allowFullScreen></iframe>
        </div>
    )
}

export default VideoKinescope
