export const CloseIcon = () => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_0_262)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.58586 10.5001L0.292969 2.20718L1.70718 0.792969L10.0001 9.08586L18.293 0.792969L19.7072 2.20718L11.4143 10.5001L19.7072 18.793L18.293 20.2072L10.0001 11.9143L1.70718 20.2072L0.292969 18.793L8.58586 10.5001Z"
                    fill="#222429"
                />
            </g>
            <defs>
                <clipPath id="clip0_0_262">
                    <rect width="20" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
