import styles from './style.module.css'
import { MechanicIcon1 } from 'src/assets/images/mechanic-icon-1'
import { MechanicIcon2 } from 'src/assets/images/mechanic-icon-2'
import { MechanicIcon3 } from 'src/assets/images/mechanic-icon-3'
import { MechanicIcon4 } from 'src/assets/images/mechanic-icon-4'

export default function Mechanics() {
    const items = [
        {
            icon: <MechanicIcon1 />,
            text: 'Выбирайте любой блок',
        },
        {
            icon: <MechanicIcon2 />,
            text: 'Смотрите обучающее видео',
        },
        {
            icon: <MechanicIcon3 />,
            text: 'Тренируйтесь на кейсах с реальными данными',
        },
        {
            icon: <MechanicIcon4 />,
            text: 'Получайте бейджи за прохождение тестов и решение кейсов',
        },
    ]
    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <h2>Механика</h2>

                <div className={styles.wrapper}>
                    {items.map((item, i) => {
                        return (
                            <div className={styles.item} key={"mechanic-"+i}>
                                <div className={styles.itemIcon}>
                                    {item.icon}
                                </div>
                                <div className={styles.itemText}>
                                    {item.text}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
