import styles from './style.module.css'
import Button from 'src/components/button'
import { useAuth } from 'src/lib/providers/auth'
import { X5dialogIcon } from 'src/assets/images/x5dialog-icon'

const RegisterPopup = () => {
    const { login } = useAuth()
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>Регистрация</h2>

                <Button fullWidth onClick={login}>
                    <span>Войти с учетной записью</span>
                    <X5dialogIcon />
                </Button>
                <a href="mailto:test@x5.ru?subject=%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%BA%20%D0%BA%D1%83%D1%80%D1%81%D1%83%20Dialog%20X5%20academy&body=%D0%9F%D1%80%D0%BE%D1%88%D1%83%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%B8%D1%82%D1%8C%20%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%BA%20%D0%BA%D1%83%D1%80%D1%81%D1%83">
                    <Button variant="secondary" fullWidth>
                        Запросить доступ
                    </Button>
                </a>
                <p>
                    Воспользуйтесь кнопкой «Запросить доступ», отправьте письмо
                    и наш менеджер свяжется с вами в ближайшее время
                </p>
            </div>
        </div>
    )
}

export default RegisterPopup
