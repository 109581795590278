import styles from './style.module.css'
import ReactDOM from 'react-dom'
import { useModal } from 'src/lib/providers/modal'
import { CloseIcon } from '../../assets/images/close-icon'

// eslint-disable-next-line react/prop-types
const Modal = ({ children }) => {
    const { isOpen, closeModal, modalType } = useModal()

    const isContentModal =
        modalType === 'case' ||
        modalType === 'lesson' ||
        modalType === 'success'

    const isTermModal = modalType === 'terms'
    const isRegisterModal = modalType === 'register'
    const isFeedbackModal = modalType === 'feedback'

    const isIntroModal = modalType === 'quiz'
    const shouldShowCloseButton = !isTermModal && !isIntroModal
    const isSmallerModal = isTermModal || isRegisterModal || isFeedbackModal
    const handleOverlayClick = e => {
        if (
            e.target === e.currentTarget &&
            !isContentModal &&
            shouldShowCloseButton
        ) {
            closeModal()
        }
    }

    const isModalOpen = isOpen

    return ReactDOM.createPortal(
        <div
            className={`${styles.modalOverlay} ${isContentModal ? styles.contentModal : ''} ${isModalOpen ? styles.show : ''} `}
            onClick={handleOverlayClick}>
            <div
                className={`${styles.modalContent} ${isSmallerModal ? styles.smallModal : ''}`}>
                {isModalOpen && children}
                {shouldShowCloseButton && (
                    <div className={styles.modalClose} onClick={closeModal}>
                        <CloseIcon />
                    </div>
                )}
            </div>
        </div>,
        document.getElementById('modal-root'),
    )
}

export default Modal
